<template>
  <div>
    <WelcomeCard @on-signout="onSignOut" label="portal.st-welcome" />

    <div class="mt-10">
      <div class="flex justify-between">
        <h2 class="mb-3 text-uppercase">
        Forcast this week <span class="text-victoria">28/4 - 5/5</span>
      </h2>
      <span class="text-uppercase text-underline font-bold text-lg">View Details</span>
      </div>
      <div class="md-layout rounded-lg p-6" :style="{ backgroundColor: '#e6e6e6' }">
        <div class="  md-layout-item border-right" v-for="item in stForcast" :key="item.id">
          <div class=" text-center">
            <h3 class="text-lg mb-6 text-uppercase">{{ item.label }}</h3>
            <h2 class="text-6xl mb-4">{{ item.value }}</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-10">
      <h2 class="mb-3 text-uppercase">Your summery</h2>
      <div class="md-layout md-gutter">
        <div class="md-layout-item" v-for="item in summery" :key="item.id">
          <div
            class="rounded-lg p-6 text-center text-white"
            :style="{ backgroundColor: item.color }"
          >
            <h3 class="text-base mb-4 opacity-50 text-uppercase">{{ item.label }}</h3>
            <h2 class="text-5xl">{{ item.value }}</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="md-layout mt-10 md-gutter">
      <div
        class="md-layout-item md-size-25 md-xsmall-size-100 md-small-size-50 md-medium-size-40 mb-5"
      >
        <TaskList
          label="portal.taskList"
          :tasks="taskList.data"
          :remaining="taskList.remaining"
          @on-change-status="onChangeStatus"
        />
      </div>
      <div
        class="md-layout-item md-size-75 md-xsmall-size-100 md-small-size-50 md-medium-size-60"
      >
        <PortalCalendarCard @on-details="onDetails" />
      </div>
      <div class="md-layout-item md-size-100 mt-5">
        <StudentPortalNotification background="#ffe5e6" />
      </div>

      <div class="md-layout-item md-size-100 mt-5">
        <StudentPortalNotification background="#e7f3e9" />
      </div>

      <div class="md-layout-item md-size-100 mt-5">
        <StudentPortalNotification background="#fffbcc" />
      </div>
    </div>
    <Dialog>
      <components :is="component" :content="content" @on-mark-complete="onMarkComplete"></components>
    </Dialog>
  </div>
</template>

<script>
import { TaskList, WelcomeCard, Dialog } from "@/components";
import PortalCalendarCard from "@/components/molecule/PortalCalendarCard";
import StudentPortalNotification from "@/components/molecule/portal/StudentPortalNotification";
import CalendarDetails from "@/components/molecule/portal/CalendarDetails";
import TaskDetails from "@/components/molecule/portal/TaskDetails";
import SingOut from "@/components/molecule/SignOut";
import summery from "@/data/portal/stSummery";
import stForcast from "@/data/portal/stForcast";
import data from "@/data/portal/data";
import { mapMutations } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      taskLists: data.taskLists,
      component: 'SingOut',
      content: {},
      summery: summery,
      stForcast: stForcast,
    };
  },
  components: {
    Dialog,
    SingOut,
    TaskList,
    TaskDetails,
    WelcomeCard,
    CalendarDetails,
    PortalCalendarCard,
    StudentPortalNotification,
  },
  computed: {
    taskList() {
      return {
          data: this.taskLists,
          remaining: this.taskLists.filter(item => !item.done).length
      }
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onSignOut(content) {
      this.component = 'SingOut';
      this.content = content;
      this.dialog(true);
    },
    onDetails() {
      this.component = 'CalendarDetails';
      this.content = {}
      this.dialog(true);
    },
     onChangeStatus(index) {
      if(!this.taskLists[index].done) {
        this.component = 'TaskDetails';
        this.content = {
          title: 'Complete 5 PTE Practice On Speaking',
          description: 'Complete 5 PTE practice on speaking secition, Please give extra effect on read aloud and repeat section cause the posibility of coming to next Mock Exam',
          index : index
        }
        this.dialog(true);
      }
      
    },
    onMarkComplete(index) {
      this.dialog(false);
      this.taskLists[index].done = !this.taskLists[index].done;
    }
  }
};
</script>
<style lang="scss" scoped>
.border-right{
  border-right: 1px solid rgb(138, 135, 135);
  &:last-child {
    border: none;
  }
}
</style>
