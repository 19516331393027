<template>
  <div class="student-portal-notification">
      <md-list :style="{backgroundColor: background}">
      <md-list-item>
        <md-icon>move_to_inbox</md-icon>
        <span class="md-list-item-text font-bold">Next class with reading section</span>
        <span class="font-bold">01 April, 2020, 7:50am</span>
      </md-list-item>

      <md-list-item>
        <md-icon>date_range</md-icon>
        <span class="md-list-item-text font-bold text-capitalize">Appointment with course teacher</span>
        <span class="font-bold">01 April, 2020, 7:50am</span>
      </md-list-item>
    </md-list>
  </div>
</template>

<script>
export default {
    props: {
        background: {
            type: String
        }
    }
}
</script>

<style lang="scss">
.student-portal-notification {
    .md-list-item {
        border-bottom: 1px solid #c1c1c1;
        &:last-child {
            border: none;
        }
    }
    .md-list.md-theme-default {
        border-radius: 10px;
        overflow: hidden;
    }
}

</style>